<template>
  <div>
    <h1 class="text-center">Welcome to Certificate Dashboard</h1>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BLink,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
  },
  data() {
    return {};
  },
  computed: {},

  mounted() {
  },
  methods: {},
};
</script>

<style></style>
